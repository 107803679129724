import React, { useEffect, useState, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { ValidateService } from "../../../JS/modules/general/validateService";
import { ErrorOutline } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";

import colors from "../../../JS/colors";

const ValidTextField = React.forwardRef((props, ref) => {
  let {
    validateTypes,
    onChange,
    onBlur,
    value,
    name,
    isDirty,
    className = "",
    showErrorIcon = false,
    renderStartAdornment,
    renderEndAdornment,
    isMultiString = false,
    helperText = "",
    customErrors = [],
    displayName,
    mask,
    unmask,
    nativeProps = {},
    maxLength,
    ...fields
  } = props;
  displayName = !!props.displayName ? props.displayName : props.name;

  const validateService = initValidationService(
    displayName,
    value,
    isMultiString
  );
  const error = useGetErrorObj(name);
  const errorMessage = useGetErrorMessage(name);

  const { onChangeHook, onBlurHook, refHook } = useRegisterField(
    name,
    value,
    validateService,
    validateTypes
  );

  const [maskValue, setMaskValue] = useState("");
  const maskHanlder = useRef(new MaskHandler(mask, unmask, setMaskValue));

  const handleChange = (e) => {
    if (maskHanlder.current.hasMaskValue(maskValue)) {
      return;
    }
    maskHanlder.current.applyUnmask(e);
    trimToMaxLength(maxLength, e);
    updateChanges(validateService, e, onChange, onChangeHook);
  };

  const handleBlur = (e) => {
    updateBlur(onBlur, e, onBlurHook);
    maskHanlder.current.applyMask(value);
  };

  const handleFocus = (e) => {
    if (maskHanlder.current.hasMaskValue(maskValue)) {
      maskHanlder.current.applyUnmask(e);
      updateChanges(validateService, e, onChange, onChangeHook);
      maskHanlder.current.updateMaskValue("");
    }
  };

  useUpdateFieldRegisterValue(value, name);
  useUpdateFieldRegisterError(customErrors, name);

  const getInputValue = maskHanlder.current.hasMaskValue(maskValue)
    ? maskValue
    : value;

  const iconsAndNativeProps = {
    startAdornment: (
      <StartAdornment renderStartAdornment={renderStartAdornment} />
    ),
    endAdornment: (
      <EndAdornment
        showErrorIcon={showErrorIcon}
        error={error}
        renderEndAdornment={renderEndAdornment}
      />
    ),
    inputProps: nativeProps,
  };

  const inputRef = (inputRef) => {
    if (ref) {
      ref.current = inputRef;
    }
    refHook(inputRef);
  };

  return (
    <TextField
      error={!!error}
      helperText={error ? errorMessage : helperText}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      className={className}
      value={getInputValue}
      name={name}
      inputRef={inputRef}
      {...fields}
      
      InputProps={iconsAndNativeProps}
    />
  );
});

ValidTextField.displayName = "ValidTextField";


export default ValidTextField;

function initValidationService(displayName, value, isMultiString) {
  return new ValidateService(displayName, value, isMultiString);
}

function useRegisterField(name, value, validateService, validateTypes) {
  const { register } = useFormContext();
  const {
    onChange: onChangeHook,
    onBlur: onBlurHook,
    ref: refHook,
  } = register(name, {
    value,
    shouldUnregister: true,
    ...validateService.getValidationsObject(validateTypes),
  });
  return { onChangeHook, onBlurHook, refHook };
}

function useUpdateFieldRegisterError(customErrors, name) {
  const { setError } = useFormContext();

  useEffect(() => {
    if (!!customErrors.length) {
      customErrors.forEach((customError) => {
        if (customError.name.length && customError.name == name) {
          setError(customError.name, {
            type: "custom",
            message: customError.message,
          });
        }
      });
    }
  }, [customErrors.length]);
}

function useUpdateFieldRegisterValue(value, name) {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!value) return;
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }, []);
}

function useGetErrorMessage(name) {
  const error = useGetErrorObj(name);
  return error?.message ?? "";
}

function useGetErrorObj(name) {
  const { formState } = useFormContext();
  return formState.errors?.[name];
}

function updateBlur(onBlur, e, onHookBlur) {
  onBlur?.(e.target.value);
  onHookBlur(e);
}

function updateChanges(validateService, e, onChange, onHookChange) {
  validateService.updateValue(e.target.value);
  onChange?.(e);
  onHookChange(e);
}

function trimToMaxLength(maxLength, e) {
  if (maxLength) {
    e.target.value = e.target.value.substring(0, maxLength);
  }
}

function StartAdornment({ renderStartAdornment }) {
  return (
    <React.Fragment>
      {!!renderStartAdornment && (
        <InputAdornment position="start">
          {renderStartAdornment?.()}
        </InputAdornment>
      )}
    </React.Fragment>
  );
}

function EndAdornment({ showErrorIcon, error, renderEndAdornment }) {
  return (
    <React.Fragment>
      {showErrorIcon && !!error?.message && (
        <InputAdornment
          position="end"
          sx={{
            color: colors.palette.primary.errorIcon,
          }}
        >
          <ErrorOutline fontSize={"small"} />
        </InputAdornment>
      )}
      {!!renderEndAdornment && (
        <InputAdornment position="end">{renderEndAdornment?.()}</InputAdornment>
      )}
    </React.Fragment>
  );
}

class MaskHandler {
  constructor(mask, unmask, setMaskValue) {
    this.mask = mask;
    this.unmask = unmask;
    this.setMaskValue = setMaskValue;
  }

  applyMask(value) {
    if (this.mask && this.unmask) {
      const maskedValue = this.mask(value);
      this.setMaskValue(maskedValue);
    }
  }

  applyUnmask(e) {
    if (this.mask && this.unmask) {
      e.target.value = this.unmask(e.target.value);
    }
  }

  hasMaskValue(maskValue) {
    return this.mask && this.unmask && maskValue;
  }

  updateMaskValue(value) {
    this.setMaskValue(value);
  }
}
