class MixpanelService{
    trackPage(page, more_props={}){
        nxTracker.trackQuotes(location.href, page, undefined, undefined, undefined, more_props);
    }

    trackClick(target, properties = {}){
        this.trackEvent('Click - ' + target, properties);
    }

    trackEvent(tracker, properties = {}){
        if(!!window.session_id) properties = {'session_id': window.session_id, ...properties};
        if(properties.page === undefined || properties.page === null) {
            properties.page = window.page_type;
        }
        if(typeof(mixpanel) != 'undefined' && !!mixpanel) mixpanel.track(tracker, properties);
    }

    trackError(properties){
        const { error_type , error_text } = properties;
        this.trackEvent('Error Annotated',{
            error_type,
            error_text
        });
    }

    async trackStatusChange({ prev_status, status, component, page }){
        await this.trackEvent("Status Changed", {
          prev_status,
          status,
          component,
          page
        });
    }

    trackRecaptchaDisplayed(properties){
        this.trackEvent('Recaptcha Displayed', properties);
    }

    trackLoginClick( properties){
        const {
            component,
            type
        } = properties;
        this.trackClick('Login', {component, type});
    }

    trackReferralShareClick(props){
        const {isPromo, social} = props
        mixpanelService.trackClick('Refer - Share', { component: `${!!isPromo ? 'Promo' : 'Page'}`, social });
    }

    trackDashboardSaveProfileTask(props = {}, page){
        const {task_name, completed_successfully} = props
        mixpanelService.trackClick(`${page} - save profile task`, {task_name, completed_successfully, ...props})
    }
}

export const mixpanelService =  new MixpanelService();
window.mixpanelService = mixpanelService;
