import {useForm} from "react-hook-form";
import { FormProvider } from "react-hook-form";
import React, { useEffect } from "react";
import {mixpanelService} from "/resources/JS/modules/tracking/MixpanelService";
import Typography from "@mui/material/Typography";
/**
 * Based off react-hook-form
 * example of usage
 *  <ValidForm onSubmit = {onSubmit}>
 *                 <ValidTextField
 *                     type="text"
 *                     name = "field1"
 *                     validateTypes = {["required", "email"]}
 *                     placeholder="Add value"
 *                     className=""
 *                     value={'Hello World'}
 *                 />
 *                <ValidSubmit/>
 *             </ValidForm>
 */

const ValidForm = (props) => {

    const {
      children,
      className = "",
      onSubmit = () => {},
      shouldFocusError = false,
      formId = "",
      preValidation = () => {},
      formError = "",
      style = {},
      noValidate = false,
    } = props;
    const methods = props.methods ?? useForm(shouldFocusError);
    const {errors, isSubmitted} = methods?.formState;

    useEffect(()=>{
        if(isSubmitted && !!errors){
        Object.keys(errors)?.forEach(error => {
            mixpanelService.trackError({error_type: error, error_text: errors[error].message});
        })}

    },[isSubmitted, errors])

    const submit = (e) =>{
        e.preventDefault();
        preValidation();
        methods.handleSubmit((e) => {
            onSubmit?.(e);
        })();
    }

    return (
        <FormProvider {...methods} >
            <form style={style} className={className} onSubmit={submit} id={formId} noValidate={noValidate}>
                {!!formError.length &&<div className='form-error-wrap'>
                    <Typography variant="caption" className='form-error' >{formError}</Typography>
                </div>}
                {children}
            </form>
        </FormProvider>
    );
}
export default ValidForm;