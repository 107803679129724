import React from 'react';
const Spinner = (props) => {

    let { displayBackdrop = true, transparent = false, style } = props;
    if (transparent) {
      style = {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        ...style,
      };
    }

  return (
    <div
      style={style}
      className={displayBackdrop ? "spinner backdrop" : "spinner"}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45px"
        height="45px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#0076DF"
          strokeWidth="10"
          r="42"
          strokeDasharray="197.92033717615698 67.97344572538566"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="0.8s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
      <div className="backdrop"></div>
    </div>
  );
};

export default Spinner;