import React , {useEffect, useState, useRef} from 'react';
export const useScreen = (callBack = null) => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
    const [size, setSize] = useState(getScreenSize);

    function getScreenSize() {
      return {
        from744: window.matchMedia("(min-width: 744px)").matches,
        upTo429: window.matchMedia("(max-width: 429px)").matches,
        upTo743: window.matchMedia("(max-width: 743px)").matches,
        from743: window.matchMedia("(min-width: 743px)").matches,
        upTo1000: window.matchMedia("(max-width: 1000px)").matches,
        upTo1024: window.matchMedia("(max-width: 1024px)").matches,
      };
    }
  
    function setScreenSize() {
      setSize(getScreenSize());
    }
  
    useEffect(() => {
      const func = () => {
        setScreenSize();
        setIsLargeScreen(window.innerWidth > 768);
        callBack?.();
      };
      window.addEventListener("resize", func);
      setScreenSize();
      return () => {
        window.removeEventListener("resize", func);
      };
    }, []);
    
    return {isLargeScreen , size}
}