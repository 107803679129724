import {capitalize, getMultiStringArray} from "./utils";
import {ZIP_LENGTH} from "./constants";

export class ValidateService{

    constructor(name, value, isMultiString){
        this.name = name;
        this.value = value;
        this.isMultiString = isMultiString;
    }

    updateValue(value){
        this.value = value;
    }

    testRegExp(expression){
        if(!!!this.value) return true;
        const regExp = new RegExp(expression);
        if(!!this.isMultiString){
            return getMultiStringArray(this.value, ',')?.map(val => regExp.test(val)).reduce((a, b) => a && !!b, true);
        }
        return regExp.test(this.value);
    }

    validatePhone(including_toll_free = false){
        if(including_toll_free){
            return this.testRegExp(/^(\+?1-?)?(((\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9]))|((\(8[087654]{2}\)|8[087654]{2})-?\d{3}))-?\d{4}$/);
        }else{
            return this.testRegExp(/^(\[0-9]{3}\ |[0-9]{3})[0-9]{3}[0-9]{4}$/);
        }
    }

    validateAddress(value) {
        if (!value) return true;
        const address_arr = value.split(" ");
        if (address_arr.length < 2) {
            return false;
        } else {
            let street = '';
            for (let i = 1; i < address_arr.length; i++) {
                street += address_arr[i];
            }
            return !isNaN(address_arr[0]) && street.length !== 0;
        }
    }

    validateEmail(){
        return this.testRegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    validateNumbersOnly(){
        return this.testRegExp(/^[0-9]*$/);
    }

    validateBBB(){
        return this.testRegExp(/^(https?:\/\/)?(www\.)?bbb.org(\/[a-zA-Z0-9(\.\?)?])?/);
    }

    validateYoutube(){
        return this.testRegExp(/^(https?:\/\/)?(www\.)?(youtube.com|youtu.be)(\/[a-zA-Z0-9(\.\?)?])?/);
    }

    validateWebsite(){
        return this.testRegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
    }

    validateYelp(){
        return this.testRegExp(/^(https?:\/\/)?(www\.)?(yelp.com)(\/[a-zA-Z0-9(\.\?)?])?/);
    }

    validateFacebook(){
        return this.testRegExp(/^(https?:\/\/)?(www\.)?facebook.com(\/[a-zA-Z0-9(\.\?)?])?/);
    }

    validateTwitter(){
        return this.testRegExp(/^(https?:\/\/)?(www\.)?twitter.com(\/[a-zA-Z0-9(\.\?)?])?/);
    }

    validateLinkedin(){
        return this.testRegExp(/^(https?:\/\/)?(www\.)?linkedin.com(\/[a-zA-Z0-9(\.\?)?])?/);
    }

    validateCharacters(){
        return this.testRegExp(/^[\w\d\s\n\/\[\]\\.,<>?;:"'`!@#$%^&*(){}_+=|~-]+$/);
    }

    getValidationsObject(validateTypes = []) {
        let object = {};
        validateTypes.forEach((validateType) => {
            const newObj = this.getSingleValidationObject(validateType);
            const type = Object.keys(newObj)[0];
            if(type in object){
                object[type] = {...object[type], ...newObj[type]};
            }else{
                object = {...object, ...this.getSingleValidationObject(validateType)}
            }

        });
        return object;
    }

    validateRadius(){
        return this.value >= 10 && this.value <= 100;
    }

    validateZip(){
        return this.value.length == 5;
    }

    getSingleValidationObject(validateType){
        if (typeof validateType === "object") {
            if ("min" in validateType) {
                return {min: {value: validateType.min, message: "Value must be greater than or equal to " + validateType.min.toLocaleString()}};
            } else if ("max" in validateType) {
                return {max: {value: validateType.max, message: "Value must be less than or equal to " + validateType.max.toLocaleString()}};
            } else {
                return {};
            }
        } else {
            switch(validateType){
                case 'required':
                    return {required: capitalize(this.name) + ' is required'};
                case 'phone':
                    return {validate: {phone: () => this.validatePhone() ||  "Please enter a valid Phone Number.",}};
                case 'phone_including_toll_free':
                    return {validate: {phone_toll_free: () => this.validatePhone(true) ||  "Please enter a valid Phone Number.",}};
                case 'address':
                    return {validate:{address: () =>  this.validateAddress(this.value) || "Please enter the street number followed by the street name.",}}
                case 'email':
                    return {validate: {email: () => this.validateEmail() || "Please insert a valid Email address.",}};
                case 'numbersOnly':
                    return {validate: {numbers: () => this.validateNumbersOnly() || this.name + " must be numeric.",}};
                case 'bbb':
                    return {validate: {bbb: () => this.validateBBB() || "BBB URL is invalid.",}};
                case 'website':
                    return {validate: {website: () =>this.validateWebsite() || "Please enter a valid URL.",}};
                case 'youtube':
                    return {validate: {youtube: () => this.validateYoutube() || "YouTube URL is invalid.",}};
                case 'facebook':
                    return {validate: {facebook: () => this.validateFacebook() || "Facebook URL is invalid.",}};
                case 'yelp':
                    return {validate: {yelp: () => this.validateYelp() || "Yelp URL is invalid.",}};
                case 'linkedin':
                    return {validate: {linkedin: () => this.validateLinkedin() || "LinkedIn URL is invalid.",}};
                case 'twitter':
                    return {validate: {twitter: () => this.validateTwitter() || "Twitter URL is invalid.",}};
                case 'chars_only':
                    return {validate: {chars:  () => this.validateCharacters() ||  "Please enter valid characters.",}};
                case 'radius':
                    return {validate: {radius: () => this.validateRadius() || "Radius must be between 10 and 100 miles.",}};
                case 'zip':
                    return {validate: {zip: () => this.validateZip() || "Please enter a valid zip code.",}};
                default:
                    return {};
            }
        }
    }
}

const testRegExp = (pattern, input) => {
  return pattern.test(input);
};

export const validatePhone = (input) => {
  const tollFreePhone = testRegExp(
    /^(\+?1-?)?(((\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9]))|((\(8[087654]{2}\)|8[087654]{2})-?\d{3}))-?\d{4}$/,
    input
  );
  const regularPhone = testRegExp(/^(\d{3}\ |\d{3})\d{3}\d{4}$/, input);

  return tollFreePhone || regularPhone;
};

export const validateEmail = (input) => {
  return testRegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    input
  );
};
  
export const validateRequired = (input) => {
  return input !== undefined && input !== null && input !== "";
};

export const validateZip = (zip) => {
    return !!zip && zip.length == ZIP_LENGTH && testRegExp(/^[0-9]+$/, zip);
}

export const validateExpirationDate = (expirationDate) => {
    if (!expirationDate || expirationDate.length !== 5) {
        return false;
    }
    const [expMonth, expYear] = expirationDate.split('/');
    if (!/^(0[1-9]|1[0-2])$/.test(expMonth)) {
        return false;
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (parseInt(currentYear.toString().slice(2)) > parseInt(expYear) || parseInt(currentYear.toString().slice(2)) + 20 < parseInt(expYear)) {
        return false;
    }

    if (parseInt(currentYear.toString().slice(2)) === parseInt(expYear)) {
        const currentMonth = currentDate.getMonth() + 1;
        if (parseInt(expMonth) < currentMonth) {
            return false;
        }
    }
    
    return true;
}
