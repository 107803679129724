import { createTheme } from '@mui/material/styles';

const colors = createTheme({
    palette: {
        primary: {
            main: "#005EB3",
            defaultText: "#475467",
            headingText: "#101828",
            subHeadingLightText: "#F2F4F7",
            subHeadingText: "#475467",
            darkerText: "#344054",
            promoInputText: "#101828",
            linkText: "#004689",

            lightGray: "#D0D5DD",
            lightestGray: "#F9FAFB",

            blue: "#005EB3",
            darkBlue: "#004689",
            darkestBlue: "#003061",
            hoveredBlue: "#004689",
            tappedBlue: "#003061",

            //statuses
            activated_text: "#5925DC",
            activated_bg: "#F4F3FF",
            account_created_text: "#B54708",
            account_created_bg: "#FFFAEB",
            invite_sent_text: "#004689",
            invite_sent_bg: "#ECF2FF",
            approved_text: "#027A48",
            approved_bg: "#ECFDF3",
            disqualified_text: "#344054",
            disqualified_bg: "#F2F4F7",

            tableCaption: "#F9FAFB",
            tableCellBorder: "#EAECF0",

            inputText: "#667085",
            defaultBorder: "#D0D5DD",
            focusedBorder: "#005EB3",

            errorText: "#D92D20",
            errorBorder: "#FDA29B",
            errorIcon: "#F04438",

            successText: "#027A48",
            successBg: "#ECFDF3"
        }
    }
});

export default colors;
